<template>
  <div class="scan">

    <ScannerCamera />

    <div class="target-camera" @click="simulateScan()">
      <v-img max-width="300" max-height="300" src="@/assets/img/cam-overlay.png" />
      <div class="scanner-explanation" :style="{backgroundColor: $vuetify.theme.themes.light.primary+ '30'}">
        <p v-if="!error" :style="{backgroundColor: $vuetify.theme.themes.light.primary + '30'}" class="ma-0 px-3 py-5 white--text font-weight-bold text-center font-weight-medium">
          {{$t("scanProductExplain")}}
        </p>
        <p v-else :style="{backgroundColor: $vuetify.theme.themes.light.error + '80'}" class="ma-0 px-3 py-5 white--text font-weight-bold text-center font-weight-medium">
          {{$t("scanProductErrorExplain")}}
        </p>
      </div>
    </div>



    <audio id="beep" ref="beep" controls hidden>
      <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
      {{$t('errorSound')}}
    </audio>

  </div>

</template>

<script>
import ScannerCamera from "@/components/subcomponents/ScannerCamera.vue";
import ProductBloc from "@/components/ProductBloc.vue";
let Barcode = require("@/assets/js/Barcode");
import {mapActions, mapState} from 'vuex'
var CatalogService = require('@/services/CatalogService.js')

export default {
  name: 'ScanProduct',
  components: {
    ScannerCamera,
  },
  data() {
    return {
      cameras: [],
      cameraToUse: null,
      error: false,
    }
  },

  created() {
    this.changeCurrentPage(this.$route.meta.page)
  },
  mounted () {
    Barcode.start(this.onDecode);
  },
  methods: {
    ...mapActions('header', [ 'changeCurrentPage' ]),

    simulateScan(){
      let code = {
        codeResult: {
          code: "6387733083543"
        }
      }
      this.onDecode(code)
    },
    onDecode(decodedString) {
      this.$refs.beep.play();
      CatalogService.getProductByEan(decodedString.codeResult.code).then(({ status, data }) => {
        if (status === 200 && this.isNotNull(data)) {
          this.$root.$emit("showAdditionalInfoModal", {product: data, showAddBasket: true})
          console.log(data)
        } else {
          this.error = true
          setTimeout(() => {
            this.error = false
          }, 5000);
        }

      })
    },
    handleFinishCheck(){
      this.$router.push( {name: "Basket"} )
    }

  },
  beforeRouteLeave(to, from, next) {
    /* eslint-disable */
    Barcode.reset()
    next();
  },
  watch: {
    cameras() {
      Barcode.reset()
      this.cameraToUse = this.cameras[0]
    },
    cameraToUse(camera) {
      Barcode.reset()
      Barcode.start(camera.device, this.onDecode);
    }
  }

};
</script>
<style scoped>

.scan {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: black;
  position: relative;
}

.target-camera {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanner-explanation {
  position: absolute;
  width: 100%;
}
.modal-action{
  border-radius: 8px;
}
</style>
